import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import ajax from "@/ajax";
import { storeInit } from '@/store/index.js'

//import '@/assets/css/base.scss'
import '@/assets/css/public-class.scss'
import '@/assets/css/iconfont.css'

var app = createApp(App)

app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(createPinia())
app.use(router)

ajax.store = storeInit()
app.config.globalProperties._ajax = ajax
app.config.globalProperties._store = ajax.store
app.config.globalProperties.numberBlur = (event, set) => {
    var k = /\d*\.?\d*%?/ 
    set.form[set.key] = (event.target.value + "").match(k)[0]
}



app.mount('#app')