/** @format */

export const routes = [
    //
    {
        path: "/",
        name: "login",
        component: () => import("@/views/user/login.vue"),
    },
    {
        path: "/reg",
        name: "register",
        component: () => import("@/views/user/register.vue"),
    },
    {
        path: "/forget",
        name: "ForgetPass",
        component: () => import("@/views/user/forgetPass.vue"),
    },
    {
        // 布局
        path: "/layout",
        name: "layout",
        component: () => import("@/views/layout.vue"),
        redirect: "/home",
        children: [
            // 首页
            {
                path: "/home",
                name: "home",
                component: () => import("@/views/home.vue"),
                meta: {
                    title: "Home",
                    mes: "Welcome to VDC platform",
                },
            },
            {
                path: "/user/messageBoard",
                name: "messageBoard",
                component: () => import("@/views/user/messageBoard.vue"),
                meta: {
                    title: "留言板",
                },
            },
        ],
    },
    // 我的课程
    {
        path: "/myCourse",
        name: "myCourse",
        component: () => import("@/views/layout.vue"),
        redirect: "/myCourseList",
        meta: {
            title: "我的课程",
        },
        children: [
            // 我的课程
            {
                path: "/myCourseList",
                name: "myCourseList",
                component: () => import("@/views/myCourse/myCourseList.vue"),
                meta: {
                    title: "我的课程",
                },
            },
            // 我的任务
            {
                path: "/myTaskList",
                name: "myTaskList",
                component: () => import("@/views/myCourse/myTaskList.vue"),
                meta: {
                    title: "任务列表",
                },
                // children:[
                //     {
                //         path: "/mesManage",
                //         name: "mesManage",
                //         component: () => import("@/views/myCourse/mesManage.vue"),
                //         meta: {
                //             title: "常见问题管理",
                //         },
                //     },
                // ]
            },
            // 学生管理
            {
                path: "/myCourseManage",
                name: "myCourseManage",
                component: () => import("@/views/myCourse/myCourseManage.vue"),
                meta: {
                    title: "学生管理",
                },
            },
            // 学情分析
            {
                path: "/studentAnalysis",
                name: "studentAnalysis",
                component: () => import("@/views/myCourse/studentAnalysis.vue"),
                meta: {
                    title: "学情分析",
                },
            },
            // 常见问题管理
            {
                path: "/mesManage",
                name: "mesManage",
                component: () => import("@/views/myCourse/mesManage.vue"),
                meta: {
                    title: "常见问题管理",
                },
            },
        ],
    },
    // 标准课程
    {
        path: "/template",
        name: "template",
        component: () => import("@/views/layout.vue"),
        redirect: "/templateCourse/templateCourse",
        meta: {
            title: "标准课程",
        },
        children: [
            // 标准课程
            {
                path: "/templateCourse/templateCourse",
                name: "templateCourse",
                component: () =>
                    import("@/views/tempCourse/templateCourse.vue"),
                meta: {
                    title: "标准课程",
                },
            },
            // 课程详情
            {
                path: "/templateCourse/templateCourseDetail",
                name: "templateCourseDetail",
                component: () =>
                    import("@/views/tempCourse/templateCourseDetail.vue"),
                meta: {
                    title: "课程详情",
                },
            },
        ],
    },
    // 数据可视化
    {
        path: "/dataVisual",
        name: "dataVisual",
        component: () => import("@/views/layout.vue"),
        redirect: "/dataVisualReport/macroAnaly",
        meta: {
            title: "数据可视报表",
        },
        children: [
            {
                path: "/dataVisualReport/macroAnaly",
                name: "dataVisual_a",
                component: () =>
                    import("@/views/dataVisualReport/iframePage.vue"),
                meta: {
                    title: "宏观分析",
                },
                children:[
                    {
                        path: "/dataVisualReport/macroAnaly",
                        name: "macroAnaly",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "宏观统计",
                        },
                    },
                    {
                        path: "/dataVisualReport/fiscal_policy",
                        name: "fiscal_policy",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "财政政策",
                        },
                    },
                    {
                        path: "/dataVisualReport/monetary_policy",
                        name: "monetary_policy",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "货币政策",
                        },
                    },
                    {
                        path: "/dataVisualReport/Accounting_macro_value_index",
                        name: "Accounting_macro_value_index",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "会计宏观价值指数",
                        },
                    },
                ]
            },
            {
                path: "/dataVisualReport/Intonation_analysis_of_financial_information",
                name: "dataVisual_b",
                component: () =>
                    import("@/views/dataVisualReport/iframePage.vue"),
                meta: {
                    title: "财经资讯",
                },
                children:[
                    {
                        path: "/dataVisualReport/Intonation_analysis_of_financial_information",
                        name: "Intonation_analysis_of_financial_information",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "财经资讯语调分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/Financial_keyword_cloud",
                        name: "Financial_keyword_cloud",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "财经关键词云",
                        },
                    },
                    {
                        path: "/dataVisualReport/Announcement_query",
                        name: "Announcement_query",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "公告查询",
                        },
                    },
                ]
            },
            {
                path: "/dataVisualReport/Industry_development_and_evaluation",
                name: "dataVisual_c",
                component: () =>
                    import("@/views/dataVisualReport/iframePage.vue"),
                meta: {
                    title: "产业分析",
                },
                children:[
                    {
                        path: "/dataVisualReport/Industry_operation_related_effects",
                        name: "Industry_operation_related_effects",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "行业经营相关效应",
                        },
                    },
                    {
                        path: "/dataVisualReport/Industry_Lerner_Index",
                        name: "Industry_Lerner_Index",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "行业勒纳指数",
                        },
                    },
                    {
                        path: "/dataVisualReport/Industrial_economy_keyword_cloud",
                        name: "Industrial_economy_keyword_cloud",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "产业经济关键词云",
                        },
                    },
                    {
                        path: "/dataVisualReport/Tone_analysis_of_industrial_economy",
                        name: "Tone_analysis_of_industrial_economy",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "产业经济语调分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/Industrial_capacity_and_clustering",
                        name: "Industrial_capacity_and_clustering",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "产业产能与聚类",
                        },
                    },
                    {
                        path: "/dataVisualReport/Industry_development_and_evaluation",
                        name: "Industry_development_and_evaluation",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "行业发展与评价",
                        },
                    },
                ]
            },
            {
                path: "/dataVisualReport/Balance_sheet_analysis",
                name: "dataVisual_d",
                component: () =>
                    import("@/views/dataVisualReport/iframePage.vue"),
                meta: {
                    title: "财务分析",
                },
                children:[
                    {
                        path: "/dataVisualReport/Financial_analysis_quarter",
                        name: "Financial_analysis_quarter",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "财务分析（含季度）",
                        },
                    },
                    {
                        path: "/dataVisualReport/Balance_sheet_analysis",
                        name: "Balance_sheet_analysis",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "资产负债表分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/Income_statement_analysis",
                        name: "Income_statement_analysis",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "利润表分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/Analysis_of_cash_flow_statement",
                        name: "Analysis_of_cash_flow_statement",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "现金流量表分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/Penetrating_financial_report_analysis",
                        name: "Penetrating_financial_report_analysis",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "穿透式财报分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/Financial_index_analysis",
                        name: "Financial_index_analysis",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "财务指标分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/Accounting_macro_value_index",
                        name: "Accounting_macro_value_index",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "会计宏观价值指数",
                        },
                    },
                    {
                        path: "/dataVisualReport/Comprehensive_evaluation_of_business_performance",
                        name: "Comprehensive_evaluation_of_business_performance",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "经营业绩综合评价",
                        },
                    },
                    {
                        path: "/dataVisualReport/DuPont_analysis",
                        name: "DuPont_analysis",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "杜邦分析",
                        },
                    },
                ]
            },
            {
                path: "/dataVisualReport/Optional_accounting_policy_application",
                name: "dataVisual_e",
                component: () =>
                    import("@/views/dataVisualReport/iframePage.vue"),
                meta: {
                    title: "会计分析",
                },
                children:[
                    {
                        path: "/dataVisualReport/stock",
                        name: "stock",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "存货",
                        },
                    },
                    {
                        path: "/dataVisualReport/fixed",
                        name: "fixed",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "固定资产",
                        },
                    },
                    {
                        path: "/dataVisualReport/intAssets",
                        name: "intAssets",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "无形资产",
                        },
                    },
                    {
                        path: "/dataVisualReport/assetImpairment",
                        name: "assetImpairment",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "资产减值",
                        },
                    },
                    {
                        path: "/dataVisualReport/investmentEstate",
                        name: "investmentEstate",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "投资性房地产",
                        },
                    },
                    {
                        path: "/dataVisualReport/payables",
                        name: "payables",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "应付项目",
                        },
                    },
                    {
                        path: "/dataVisualReport/receivables",
                        name: "receivables",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "应收项目",
                        },
                    },
                    {
                        path: "/dataVisualReport/equity",
                        name: "equity",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "股本",
                        },
                    },
                    {
                        path: "/dataVisualReport/loan",
                        name: "loan",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "借款",
                        },
                    },
                    {
                        path: "/dataVisualReport/govGrants",
                        name: "govGrants",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "政府补助",
                        },
                    },
                    {
                        path: "/dataVisualReport/nonRecurringLoss",
                        name: "nonRecurringLoss",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "非经常性损益",
                        },
                    },
                    {
                        path: "/dataVisualReport/longDeferredExpenses",
                        name: "longDeferredExpenses",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "长期待摊费用",
                        },
                    },
                    {
                        path: "/dataVisualReport/longInvestment",
                        name: "longInvestment",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "长期股权投资",
                        },
                    },
                    {
                        path: "/dataVisualReport/undistrProfit",
                        name: "undistrProfit",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "未分配利润",
                        },
                    },
                    {
                        path: "/dataVisualReport/income",
                        name: "income",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "收入",
                        },
                    },
                    {
                        path: "/dataVisualReport/periodCost",
                        name: "periodCost",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "期间费用",
                        },
                    },
                    {
                        path: "/dataVisualReport/taxesSurcharges",
                        name: "taxesSurcharges",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "税金及附加",
                        },
                    },
                    {
                        path: "/dataVisualReport/industryAnaly",
                        name: "industryAnaly",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "行业分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/financialAna",
                        name: "financialAna",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "财务分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/investIna",
                        name: "investIna",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "投融资",
                        },
                    },
                    {
                        path: "/dataVisualReport/strategicAnaly",
                        name: "strategicAnaly",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "战略分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/prospectAnaly",
                        name: "prospectAnaly",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "前景分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/eveAnal",
                        name: "eveAnal",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "事件分析",
                        },
                    },
                    {
                        path: "/dataVisualReport/eveSearch",
                        name: "eveSearch",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "事件查询",
                        },
                    },
                    {
                        path: "/dataVisualReport/stockInv",
                        name: "stockInv",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "股票投资",
                        },
                    },
                    {
                        path: "/dataVisualReport/finFraud",
                        name: "finFraud",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "财务舞弊",
                        },
                    },
                    {
                        path: "/dataVisualReport/resAudit",
                        name: "resAudit",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "经责审计",
                        },
                    },
                    {
                        path: "/dataVisualReport/finCustom",
                        name: "finCustom",
                        component: () => import("@/views/noPage.vue"),
                        meta: {
                            title: "财务指标定制",
                        },
                    },
                    {
                        path: "/dataVisualReport/Optional_accounting_policy_application",
                        name: "Optional_accounting_policy_application",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "可选择会计政策应用",
                        },
                    },
                    {
                        path: "/dataVisualReport/Application_of_important_accounting_estimates",
                        name: "Application_of_important_accounting_estimates",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "重要会计估计应用",
                        },
                    },
                    {
                        path: "/dataVisualReport/Company_accounting_method",
                        name: "Company_accounting_method",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "公司会计方法",
                        },
                    },
                    {
                        path: "/dataVisualReport/Employee_compensation",
                        name: "Employee_compensation",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "职工薪酬",
                        },
                    },
                    {
                        path: "/dataVisualReport/Investment_and_financing",
                        name: "Investment_and_financing",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "投融资",
                        },
                    },
                    {
                        path: "/dataVisualReport/Business_combination",
                        name: "Business_combination",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "企业合并",
                        },
                    },
                ]
            },
            {
                path: "/dataVisualReport/Income_fraud",
                name: "dataVisual_f",
                component: () =>
                    import("@/views/dataVisualReport/iframePage.vue"),
                meta: {
                    title: "审计内控",
                },
                children:[
                    {
                        path: "/dataVisualReport/Income_fraud",
                        name: "Income_fraud",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "收入舞弊",
                        },
                    },
                    {
                        path: "/dataVisualReport/Asset_fraud",
                        name: "Asset_fraud",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "资产舞弊",
                        },
                    },
                    {
                        path: "/dataVisualReport/Expense_fraud",
                        name: "Expense_fraud",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "费用舞弊",
                        },
                    },
                    {
                        path: "/dataVisualReport/Delist_due_to_financial",
                        name: "Delist_due_to_financial",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "财务舞弊（退市）",
                        },
                    },
                    {
                        path: "/dataVisualReport/auditMatters",
                        name: "auditMatters",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "关键审计事项",
                        },
                    },
                    {
                        path: "/dataVisualReport/Responsible_audit",
                        name: "Responsible_audit",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "经责审计",
                        },
                    },
                    {
                        path: "/dataVisualReport/M_index",
                        name: "M_index",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "M指数",
                        },
                    },
                    {
                        path: "/dataVisualReport/Earnings_management_identify",
                        name: "Earnings_management_identify",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "盈余管理特征识别",
                        },
                    },
                    {
                        path: "/dataVisualReport/Early_signal_identify",
                        name: "Early_signal_identify",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "常见预警信号识别",
                        },
                    },
                    {
                        path: "/dataVisualReport/Announcement_query",
                        name: "Announcement_query",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "公告查询",
                        },
                    },
                    {
                        path: "/dataVisualReport/Inquiry_query",
                        name: "Inquiry_query",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "资讯查询",
                        },
                    },
                ]
            },
            {
                path: "/dataVisualReport/Strategic_management",
                name: "dataVisual_g",
                component: () =>
                    import("@/views/dataVisualReport/iframePage.vue"),
                meta: {
                    title: "财务决策",
                },
                children:[
                    {
                        path: "/dataVisualReport/Strategic_management",
                        name: "Strategic_management",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "战略管理",
                        },
                    },
                    {
                        path: "/dataVisualReport/Performance_evaluation",
                        name: "Performance_evaluation",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "业绩评价",
                        },
                    },
                    {
                        path: "/dataVisualReport/Portfolio_investment",
                        name: "Portfolio_investment",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "证券投资",
                        },
                    },
                    {
                        path: "/dataVisualReport/Business_combination_finance",
                        name: "Business_combination_finance",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "企业合并",
                        },
                    },
                    {
                        path: "/dataVisualReport/Credit_decision",
                        name: "Credit_decision",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "信贷决策",
                        },
                    },
                    {
                        path: "/dataVisualReport/Accounting_policy",
                        name: "Accounting_policy",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "会计政策",
                        },
                    },
                    {
                        path: "/dataVisualReport/Strategic_choice_and_evaluation",
                        name: "Strategic_choice_and_evaluation",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "战略选择与评价",
                        },
                    },
                    {
                        path: "/dataVisualReport/Risk_identification_and_evaluation",
                        name: "Risk_identification_and_evaluation",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "风险识别与评价",
                        },
                    },
                    {
                        path: "/dataVisualReport/Performance_driven_and_evaluation",
                        name: "Performance_driven_and_evaluation",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "业绩驱动与评价",
                        },
                    },
                    {
                        path: "/dataVisualReport/Core_competitiveness_analysis",
                        name: "Core_competitiveness_analysis",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "核心竞争力分析",
                        },
                    },
                    
                    {
                        path: "/dataVisualReport/Management_decision",
                        name: "Management_decision",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "经营管理决策",
                        },
                    },
                    {
                        path: "/dataVisualReport/Investment_expansion_decision",
                        name: "Investment_expansion_decision",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "投资扩张决策",
                        },
                    },
                    {
                        path: "/dataVisualReport/Cost_leadership_strategy",
                        name: "Cost_leadership_strategy",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "成本领先战略",
                        },
                    },
                    {
                        path: "/dataVisualReport/Capital_structure_management",
                        name: "Capital_structure_management",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "资本结构管理",
                        },
                    },
                    {
                        path: "/dataVisualReport/Financial_statement_forecast",
                        name: "Financial_statement_forecast",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "财务报表预测",
                        },
                    },
                ]
            },
            {
                path: "/dataVisualReport/Resolution_of_the_board_of_directors",
                name: "dataVisual_h",
                component: () =>
                    import("@/views/dataVisualReport/iframePage.vue"),
                meta: {
                    title: "公司治理",
                },
                children:[
                    {
                        path: "/dataVisualReport/Resolution_of_the_board_of_directors",
                        name: "Resolution_of_the_board_of_directors",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "董事会决议",
                        },
                    },
                ]
            },
            {
                path: "/dataVisualReport/Stock_trading",
                name: "dataVisual_i",
                component: () =>
                    import("@/views/dataVisualReport/iframePage.vue"),
                meta: {
                    title: "证券交易",
                },
                children:[
                    {
                        path: "/dataVisualReport/Stock_trading",
                        name: "Stock_trading",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "股票交易",
                        },
                    },
                    {
                        path: "/dataVisualReport/Stock_investment",
                        name: "Stock_investment",
                        component: () =>
                            import("@/views/dataVisualReport/iframePage.vue"),
                        meta: {
                            title: "股票投资",
                        },
                    },
                ]
            },
        ],
    },
    // 智能财务助手 & 智能文本检索
    {
        path: "/smartFinance",
        name: "smartFinance",
        component: () => import("@/views/layout.vue"),
        redirect: "/smartFinanceHelper/smartFinancialAnalysis",
        meta: {
            title: "智能财务助手",
        },
        children: [
            {
                path: "/smartFinanceHelper/smartTest",
                name: "smartTest",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "轮播",
                },
            },
            {
                path: "/smartFinanceHelper/smartFinancialAnalysis",
                name: "smartFinancialAnalysis",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "智能财务分析",
                },
            },
            {
                path: "/smartFinanceHelper/smartPerformanceEvaluation",
                name: "smartPerformanceEvaluation",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "智能业绩评价",
                },
            },
            {
                path: "/smartFinanceHelper/smartAccountAnalysis",
                name: "smartAccountAnalysis",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "智能会计分析",
                },
            },
            {
                path: "/smartFinanceHelper/smartFinancialAudit",
                name: "smartFinancialAudit",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "智能财务审计",
                },
            },
            {
                path: "/smartFinanceHelper/smartFinancialDecisions",
                name: "smartFinancialDecisions",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "智能财务决策",
                },
            },
            {
                path: "/smartFinanceHelper/smartInfoAnalysis",
                name: "smartInfoAnalysis",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "智能资讯分析",
                },
            },
            {
                path: "/smartFinanceHelper/smartExpertQA",
                name: "smartExpertQA",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "智能专家问答",
                },
            },

            {
                path: "/smartFinanceHelper/smartWordQuery",
                name: "smartWordQuery",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "词频查询",
                },
            },
            {
                path: "/smartFinanceHelper/smartSentenceRetrieval",
                name: "smartSentenceRetrieval",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "句式检索",
                },
            },
            {
                path: "/smartFinanceHelper/smartPassageRetrieval",
                name: "smartPassageRetrieval",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "段落检索",
                },
            },
            {
                path: "/smartFinanceHelper/smartPassageDictionary",
                name: "smartPassageDictionary",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "段落字典",
                },
            },
            {
                path: "/smartFinanceHelper/smartSyntacticDictionary",
                name: "smartSyntacticDictionary",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "句义字典",
                },
            },
            {
                path: "/smartFinanceHelper/smartKPIDictionary",
                name: "smartKPIDictionary",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "KPI字典",
                },
            },
            {
                path: "/smartFinanceHelper/smartFeatureDictionary",
                name: "smartFeatureDictionary",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "01特征字典",
                },
            },
            {
                path: "/smartFinanceHelper/smartAcaciaContent",
                name: "smartAcaciaContent",
                component: () =>
                    import("@/views/smartFinanceHelper/iframePageFinance.vue"),
                meta: {
                    title: "相似内容",
                },
            },

        ],
    },
    // 用户管理
    {
        path: "/user",
        name: "user",
        component: () => import("@/views/layout.vue"),
        redirect: "/user/usersetting",
        meta: {
            title: "用户管理",
        },
        children: [
            // usersetting
            {
                path: "/user/usersetting",
                name: "usersetting",
                component: () => import("@/views/user/usersetting.vue"),
                meta: {
                    title: "Setting",
                },
            },
        ],
    },
    {
        path: "/layoutReport",
        name: "layoutReport",
        component: () => import("@/views/layoutReport.vue"),
    },
    {
        // 布局
        path: "/reportList",
        name: "reportList",
        component: () => import("@/views/layout.vue"),
        redirect: "/reportList/list",
        meta: {
            title: "财经百校",
        },
        children: [
            // supportingDocumentation
            {
                path: "/reportList/index",
                name: "reportList_index",
                component: () => import("@/views/reportList/index.vue"),
                meta: {
                    title: "优质报告",
                },
            },
            {
                path: "/reportList/list",
                name: "reportList_list",
                component: () => import("@/views/reportList/list.vue"),
                meta: {
                    title: "报告列表",
                },
            },
            {
                path: "/reportList/details",
                name: "reportList_details",
                component: () => import("@/views/reportList/details.vue"),
                meta: {
                    title: "报告详情",
                },
            },
        ],
    },
    {
        // 布局
        path: "/maintain",
        name: "maintain",
        component: () => import("@/views/layout.vue"),
        redirect: "/maintain/index",
        meta: {
            title: "功能维护",
        },
        children: [
            // supportingDocumentation
            {
                path: "/maintain/index",
                name: "maintain_index",
                component: () => import("@/views/maintain/index.vue"),
                meta: {
                    title: "功能首页",
                },
            },
            {
                path: "/maintain/supportingDocumentation",
                name: "supportingDocumentation",
                component: () =>
                    import("@/views/maintain/supportingDocumentation.vue"),
                meta: {
                    title: "支持文档",
                },
            },
            {
                path: "/maintain/hundredSchools",
                name: "hundredSchools",
                component: () => import("@/views/maintain/hundredSchools.vue"),
                meta: {
                    title: "财经百校",
                },
            },
        ],
    },
    {
        path: "/test",
        name: "test",
        component: () => import("@/views/test.vue"),
    },
    {
        path: "/e",
        name: "e",
        component: () => import("@/views/echartsTest.vue"),
    },
    
    /*{
      //常用写法,通过正则表达式,如果路由不匹配去这个404组件
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../view/home/Home.jsx')
    },*/
];
