/** @format */

import axios from "./axios.js";
import Qs from "qs";
// import { Dialog } from 'vant';
import { ElMessage } from "element-plus";
import { test, formal } from "@/config/index";

var base_url = "http://106.12.82.74:8926/";
var powerbi = "//app.powerbi.cn/";
var aie = "//192.168.0.73:8081/";
if (test) {
    console.log("开发环境");
    // base_url = config_url || "http://192.168.0.183:9000"; //杨旭
    // base_url = config_url || "http://192.168.0.65:9000"; //石继龙
    // base_url = config_url || "http://192.168.0.42:9000"; //李士越
    // base_url = config_url || 'http://49.235.231.224:8888';
    // base_url = config_url || "http://192.168.0.36:8081"; //36服务器
    // base_url = config_url || "http://192.168.0.36:3101"; //陪标的接口
    base_url = config_url || "http://119.45.235.140:4131"; //财智云
    aie = "//192.168.1.73:8082/";
} else {
    console.log("生产环境");
    base_url = config_url || "http://49.235.231.224:8888";
    aie = "//1.13.246.53:8081/";
}

var url = {
    echarts: "/static/echarts/index.html?x=" + Math.random(),
    reg: base_url + "/Api/V1/Table_User/AddUserInfo/", //注册
    login: base_url + "/Api/V1/Login/Login/", //登录 //LoginRefClassCount Login
    Select_Schools: base_url + "/Api/V1/Common/GetSchoolList/", //学校数据
    reuser: base_url + "/Api/V1/Table_User/UpdateUserInfo/", //修改个人信息
    repwd: base_url + "/Api/V1/Table_User/UpdateUserPwd/", //修改密码
    SaveFile: base_url + "/Api/V1/Base_RelationFile/SaveFile/",
    UploadTest: base_url + "/Api/V1/Base_RelationFile/UploadTest/",
    GetUserInfo: base_url + "/Api/V1/Table_User/GetUserInfo/",

    GetReport_r: base_url + "/Api/V1/Table_Report!!/GetReport!!/",
    AddReport_r: base_url + "/Api/V1/Table_Report!!/AddReport!!/",
    UpdateReport_r: base_url + "/Api/V1/Table_Report!!/UpdateReport!!/",
    SubmitReport_r: base_url + "/Api/V1/Table_Report!!/SubmitReport!!/",
    GetReportVase_r: base_url + "/Api/V1/Table_Report!!/GetReportVase/",
    GetStudentAnalysis: base_url + "/Api/V1/Table_StudentList/GetReport/",

    GetTaskList: base_url + "/Api/V1/AtandardClassModel/GetAtandardClassModel/",
    CreateClass: base_url + "/Api/V1/Table_ClassList/AddClassList/",
    getTeacherClass: base_url + "/Api/V1/Table_ClassList/GetClassList/", //教师 我的课程
    getStudentClass: base_url + "/Api/V1/Table_StudentList/GetStudentList", //学生 我的课程
    getTeacherTask: base_url + "/Api/V1/Table_TaskList/GetTaskList/",
    updateClassList: base_url + "/Api/V1/Table_ClassList/UpdateClassList/",
    DeleteClassList: base_url + "/Api/V1/Table_ClassList/DeleteClassList/",
    UpdateTaskList: base_url + "/Api/V1/Table_TaskList/UpdateTaskList/",
    DeleteTaskList: base_url + "/Api/V1/Table_TaskList/DeleteTaskList/",
    UpdateTaskListAll: base_url + "/Api/V1/Table_TaskList/UpdateTaskListAll/",
    UploadFile: base_url + "/Api/V1/Table_ReportSupportingDocumentation/UploadFile/",
    UploadTaskAttachmentFile: base_url + "/Api/V1/Table_TaskList/UploadTaskAttachmentFile/", //教师端上传任务附件
    UpdateTaskListAttachment: base_url + "/Api/V1/Table_TaskList/UpdateTaskListAttachment/", //教师端上传任务附件
    DownloadFile: base_url + "/Api/V1/Table_ReportSupportingDocumentation/DownloadFile/",
    GetStudentListForManage: base_url + "/Api/V1/Table_StudentList/GetStudentListForManage/", //课程管理
    WordToPDF: base_url + "/Api/V1/Common/WordToPDF",
    GetStudentListForManage: base_url + "/Api/V1/Table_StudentList/GetStudentListForManage/",
    WordToPDF: base_url + "/Api/V1/Common/WordToPDF",
    // cf05c89a697205becc46a9bef1fdb0e2b1330820
    CategoryList: base_url + "/Api/V1/Common/CategoryList/",
    GetReport: base_url + "/Api/V1/Table_Report/GetReport/",
    AddReport: base_url + "/Api/V1/Table_Report/AddReport/",
    UpdateReport: base_url + "/Api/V1/Table_Report/UpdateReport/",
    DeleteReport: base_url + "/Api/V1/Table_Report/DeleteReport/",
    CategoryList: base_url + "/Api/V1/Common/CategoryList/",
    GetReportSupportingDocumentation: base_url + "/Api/V1/Table_ReportSupportingDocumentation/GetReportSupportingDocumentation/",
    AddReportSupportingDocumentation: base_url + "/Api/V1/Table_ReportSupportingDocumentation/AddReportSupportingDocumentation/",
    UpdateReportSupportingDocumentation: base_url + "/Api/V1/Table_ReportSupportingDocumentation/UpdateReportSupportingDocumentation/",
    DeleteReportSupportingDocumentation: base_url + "/Api/V1/Table_ReportSupportingDocumentation/DeleteReportSupportingDocumentation/",
    GetClassListByPollCode: base_url + "/Api/V1/Table_ClassList/GetClassListByPollCode", //加入班级 注册码查询
    getPollCompany: base_url + "/Api/V1/上市公司属性表/Get上市公司属性表", //加入班级 获取公司列表
    getPollCompany_view: base_url + "/Api/V1/上市公司属性表/Get上市公司属性表_可视化", //学生管理 修改分析对象
    AddStudentList: base_url + "/Api/V1/Table_StudentList/AddStudentList", //加入班级
    GetMainTable: base_url + "/Api/V1/主表项目_筛选/Get主表项目_筛选", //学生端 分析对象 主表数据
    GetMainTable_view: base_url + "/Api/V1/主表项目_筛选/Get主表项目_筛选可视化", //学生端 分析对象 主表数据
    GetStockTable: base_url + "/Api/V1/主表项目_筛选/Get存货_筛选", //学生端 分析对象 存货
    GetStockTable_view: base_url + "/Api/V1/主表项目_筛选/Get存货_筛选可视化", //学生端 分析对象 存货
    GetInTable: base_url + "/Api/V1/主表项目_筛选/Get行业战略_筛选", //学生端 分析对象 行业战略
    GetInTable_view: base_url + "/Api/V1/主表项目_筛选/Get行业战略_筛选可视化", //学生端 分析对象 行业战略
    tableDownLoad: base_url + "/Api/V1/主表项目_筛选/筛选Export", //学生端 导出表
    tableDownLoad_view: base_url + "/Api/V1/主表项目_筛选/筛选Export_可视化", //学生端 导出表
    UpdateManageTask: base_url + "/Api/V1/Table_TaskList/UpdateManageTask", //重置任务
    DeleteStudentList: base_url + "/Api/V1/Table_StudentList/DeleteStudentList", //移除学生
    selectCPY: base_url + "/Api/V1/上市公司属性表/GetCompanyList", //创建课程 查询公司列表
    AddStudentList: base_url + "/Api/V1/Table_StudentList/AddStudentList", //加入班级
    AddUpdateOnlyGuidstuAnalysisObj: base_url + "/Api/V1/Table_StudentList/UpdateStudentListOnlyGuidstuAnalysisObj", //更改学生列表
    UpdateTaskListAllDeadline: base_url + "/Api/V1/Table_TaskList/UpdateTaskListAllDeadline", //一键修改截止日期
    // cf05c89a697205becc46a9bef1fdb0e2b1330820

    insertrecord: base_url + "/insertrecord/", // PBI
    GetReportList: base_url + "/Api/V1/Table_Report/GetReportList/",
    GetReportCollectionList: base_url + "/Api/V1/Table_Report/GetReportCollectionList/",
    GetSchoolReport: base_url + "/Api/V1/Table_Report/GetSchoolReport/",
    CollectionReport: base_url + "/Api/V1/Table_Report/CollectionReport/",
    GiveReport: base_url + "/Api/V1/Table_Report/GiveReport/",
    GetReportDiscussList: base_url + "/Api/V1/Table_ReportDiscuss/GetReportDiscussList/",
    AddReportDiscuss: base_url + "/Api/V1/Table_ReportDiscuss/AddReportDiscuss/",
    AddReportDiscussReply: base_url + "/Api/V1/Table_ReportDiscussReply/AddReportDiscussReply/",
    DeleteReportDiscuss: base_url + "/Api/V1/Table_ReportDiscuss/DeleteReportDiscuss/",
    DeleteReportDiscussReply: base_url + "/Api/V1/Table_ReportDiscussReply/DeleteReportDiscussReply/",
    GetDiscussReplayList: base_url + "/Api/V1/Table_ReportDiscuss/GetDiscussReplayList/",

    GetStudentScore: base_url + "/Api/V1/Common/GetStudentScore/", //考核初始显示数据
    UpdateStudentGrade: base_url + "/Api/V1/Table_ClassList/UpdateStudentGrade", // 评级
    StudentScoreExport: base_url + "/Api/V1/Common/StudentScoreExport", //成绩导出
    DownWordOrPDF: base_url + "/Api/V1/Common/DownWordOrPDF", //样本导出
    StudentUploadFile: base_url + "/Api/V1/Common/GetClassStudentUploadFile",//上传文件导出
    GetSupportingDocuments: base_url + "/Api/V1/Common/GetSupportingDocuments", //获取报告右侧链接
    MyUploadFile: base_url + "/Api/V1/Table_TaskList/MyUploadFile/", //学生 我的上传
    AddUpdateReportMyUpload: base_url + "/Api/V1/Table_TaskList/AddUpdateReportMyUpload", //学生 我的上传
    AddUpdatePwd: base_url + "/Api/V1/Table_TaskList/AddUpdatePwd", //重置密码
    ClassCount: base_url + "/Api/V1/Login/ClassCount",
    UndoTaskRelease: base_url + "/Api/V1/Table_TaskList/UndoTaskRelease", //撤销单个任务发布
    UndoTaskReleaseAll: base_url + "/Api/V1/Table_TaskList/UndoTaskReleaseAll", //撤销选中行任务发布
    UpdateManageTaskALL: base_url + "/Api/V1/Table_TaskList/UpdateManageTaskALL", //学生管理 一键重置任务
    GetClassStudentScoreData: base_url + "/Api/V1/Common/GetClassStudentScoreData", //学情分析
    GetPbiLink: base_url + "/Api/V1/Table_PbiLink/GetPbiLink", //学情分析
    GetClassStudentScoreDataSubmit: base_url + "/Api/V1/Common/GetClassStudentScoreDataSubmit", //学情分析
    StudentDownWord: base_url + "/Api/V1/Common/StudentDownWord",//学生端下载样本报告

    GetcourseIndex: base_url + "/Api/V1/AtandardClassModel/GetcourseIndex", //
    GetUpdate: base_url + "/Api/V1/Table_User/GetUpdate", //
    GetReportSchoolList: base_url + "/Api/V1/Common/GetReportSchoolList", //
    CreateDoc: base_url + "/Api/V1/Common/CreateDoc", //

    AddClassStudentDiscuss: base_url + "/Api/V1/Table_ClassStudentDiscuss/AddClassStudentDiscuss",//发布留言
    GetStudentDiscuss: base_url + "/Api/V1/Table_ClassStudentDiscuss/GetStudentDiscuss",//获取留言
    UpdataIsRead: base_url + "/Api/V1/Table_ClassStudentDiscuss/UpdataIsRead", //已读
    UploadImg: base_url + "/Api/V1/Table_ClassStudentDiscuss/UploadTest", //留言上传图片
    UploadAnnouncement: base_url + "/Api/V1/Table_Announcement/UploadTest", //常见问题上传图片
    AddAnnouncement: base_url + "/Api/V1/Table_Announcement/AddAnnouncement", //常见问题发布
    GetAnnouncement: base_url + "/Api/V1/Table_Announcement/GetAnnouncement", //常见问题查询
    UpdateAnnouncement: base_url + "/Api/V1/Table_Announcement/UpdateAnnouncement", //常见问题编辑
    DeleteAnnouncement: base_url + "/Api/V1/Table_Announcement/DeleteAnnouncement", //常见问题删除
    getMesClass: base_url + "/Api/V1/Table_ClassStudentDiscuss/GetClassList", //留言查询班级
    getMesTask: base_url + "/Api/V1/Table_ClassStudentDiscuss/GetTaskList", //留言查询任务
    UpdataIsReadsettled: base_url + "/Api/V1/Table_ClassStudentDiscuss/UpdataIsReadsettled", //已解决
    GetMessage: base_url + "/Api/V1/Table_ClassStudentDiscuss/GetMessage", //任务未读提示
    GetReportQuestionMaster: base_url + "/Api/V1/Table_ReportQuestionDate/GetReportQuestionMaster", //获取教学样本的问题

    AddReportQuestionDate: base_url + "/Api/V1/Table_ReportQuestionDate/AddReportQuestionDate", //问杨旭
    GetReportQuestionDate: base_url + "/Api/V1/Table_ReportQuestionDate/GetReportQuestionDate", //问杨旭
    GetReportDetailQuestionMaster: base_url + "/Api/V1/Table_ReportQuestionDate/GetReportDetailQuestionMaster",

    // 知识点
    GetClassTaskList: base_url + "/Api/V1/Table_ClassList/GetClassTaskList", //获取班级下已发布任务名称列表
    GetStudentKnowledgePoints: base_url + "/Api/V1/Table_ClassList/GetStudentKnowledgePoints", //获取指定任务的知识点统计信息

    // 忘记密码
    CheckUser: base_url + "/Api/V1/Common/CheckUser", //第一步
    SendEmail: base_url + "/Api/V1/Common/SendEmail", //发送邮件
    UpdatePwdByEmail: base_url + "/Api/V1/Table_User/UpdatePwdByEmail", //第二步

    AddthesisDdta: base_url + "/Api/V1/Table_thesisDdta/AddthesisDdta", //论文小助手统计
    
    GetReportviewTop10List: base_url + "/Api/V1/Reportreport/GetReportviewTop10List", //浏览量前10列表
    GetReportDissTop10List: base_url + "/Api/V1/Reportreport/GetReportDissTop10List", //评论量前10列表
    GetReportviewList: base_url + "/Api/V1/Reportreport/GetReportviewList", //浏览量汇总
    GetReportDissList: base_url + "/Api/V1/Reportreport/GetReportDissList", //评论量汇总
    GetReporGiveList: base_url + "/Api/V1/Reportreport/GetReporGiveList", //点赞量汇总
    
};
var obj = {
    url,
    base_url,
    powerbi,
    aie,
    scope: {},
    post(config) {
        var p_url = url[config.url] || config.url;
        config.data = config.data || {};
        /*
            ID: "114"
            account: "001"
            email: "yk@163.com"
            msg: "登录成功"
            name: "liu"
            school: "产品支持"
            telephone: "18640195989"
        */
        if (
            config.url != "login" &&
            this.store.Header &&
            this.store.Header.Guid
        ) {
            config.data.Header = this.store.Header;
        }
        axios
            .post(p_url, config.data)
            .then((res) => {
                if (res.data || res.data.toString) {
                    // console.log("res.data:",config.url,res.data)
                    if (res.data.Status) {
                        switch (res.data.Status) {
                            case -3:
                            case "-3":
                                // 您还未登陆
                            case -7:
                            case "-7":
                                // 超时
                                localStorage.Header = "";
                                this.store.out_url =
                                    location.pathname + location.search;
                                this.app.$router.replace({ name: "login" });
                                break;
                            case -6:
                            case "-6":
                                // 权限不足
                                if (this.app.$route.matched.length > 1) {
                                    this.app.$router.back();
                                } else {
                                    this.app.$router.replace({ name: "login" });
                                }
                                break;
                        }
                        if (config.fail) {
                            config.fail(res.data);
                            return;
                        }
                        ElMessage({
                            message: "提示:" + res.data.message,
                            type: "error",
                        });
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return;
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    config.success && config.success(res.data);
                    return;
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: "error",
                });
            })
            .catch((error) => {
                console.log("error", [error]);
                var err_res = {
                    msg: "接口返回失败",
                };
                if (error.response) {
                    err_res.msg = {
                            "Internal Server Error": "内部服务器错误",
                        } [error.response.statusText] ||
                        (error.response.data && error.response.data.Message) ||
                        "接口返回失败";
                } else {
                    err_res.msg = "页面程序错误";
                }
                if (config.fail) {
                    config.fail({
                        // errCode: 500,
                        msg: err_res.msg,
                    });
                } else {
                    ElMessage({
                        message: err_res.msg,
                        grouping: true,
                        type: "error",
                    });
                    if (!error.response) {
                        console.error(error.stack);
                    }
                }
                config.error &&
                    config.error({
                        url: p_url,
                    });
            });
    },
    get(config) {
        var p_url = url[config.url] || config.url;
        config.data = config.data || {};
        /*
            ID: "114"
            account: "001"
            email: "yk@163.com"
            msg: "登录成功"
            name: "liu"
            school: "产品支持"
            telephone: "18640195989"
        */
        /*if(this.store.user.ID){
            config.data.school = this.store.user.school
            config.data.account = this.store.user.account
            config.data.name = this.store.user.name
        }
        let params = new URLSearchParams();
        for (var i in config.data) {
            if (config.data[i] instanceof Array) {
                for (var j = 0; j < config.data[i].length; j++) {
                    params.append(i, config.data[i][j]);
                };
            } else if (config.data[i] instanceof Object) {
                params.append(i, JSON.stringify(config.data[i]));
            } else {
                params.append(i, config.data[i]);
            }
        };
        config.data = params;*/
        console.log("get", p_url);
        axios
            .get(p_url, config.data, {
                maxContentLength: 4000,
            })
            .then(function(res) {
                if (res.data || res.data.toString) {
                    if (res.data.errCode || res.data.Status) {
                        if (config.fail) {
                            config.fail(res.data);
                            return;
                        }
                        ElMessage({
                            message: "提示:" + res.data.message,
                            type: "error",
                        });
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return;
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    config.success && config.success(res.data);
                    return;
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: "error",
                });
            })
            .catch(function(error) {
                if (config.fail) {
                    config.fail({
                        errCode: 500,
                        msg: "接口返回失败",
                    });
                } else {
                    ElMessage({
                        message: "接口返回失败",
                        grouping: true,
                        type: "error",
                    });
                }
                config.error &&
                    config.error({
                        url: p_url,
                    });
            });
    },
    url_obj() {
        return Qs.parse(location.search.replace("?", ""));
    },
    url_str(obj, _h = "") {
        return _h + Qs.stringify(obj);
    },
    get_url_argument(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = decodeURI(window.location.search).substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },
    get_url_argument_obj(str) {
        var obj = {};
        var reg = new RegExp("([^\\?|&]*)=([^&]*)", "g");
        var r = decodeURI(str || window.location.search).match(reg);
        for (var i = 0; i < r.length; i++) {
            var t = r[i].split("=");
            obj[t[0]] = t[1];
        }
        return obj;
    },
    url_passer(obj, key) {
        var _this_ = this;
        var returnStr = "";
        switch (typeof obj) {
            case "string":
            case "number":
            case "boolean":
                returnStr += "&" + key + "=" + obj;
                break;
            default:
                for (var i in obj) {
                    if (typeof obj[i] !== "function") {
                        if (key && key !== "?" && key !== "&") {
                            returnStr += _this_.url_passer(
                                obj[i],
                                key + "[" + i + "]"
                            );
                        } else {
                            returnStr += _this_.url_passer(obj[i], i);
                        }
                    }
                }
                break;
        }
        switch (key) {
            case "&":
                return returnStr.replace(/^./, "");
            case "?":
                return returnStr.replace(/^./, "?");
        }
        /*if (key === '?') {
            return returnStr.replace(/^./, '?')
        } else if (key === '&') {
            return returnStr.replace(/^./, '')
        }*/
        return returnStr;
    },
    column_prop_filter(item, first = [], not = []) {
        var arr = [];
        var keys = Object.keys(item);
        // console.log("column_prop_filter",keys,first,not)
        arr = first.filter((a_item) => {
            return keys.indexOf(a_item) != -1;
        });
        arr = arr.concat(
            keys.filter((a_item) => {
                return [...first, ...not].indexOf(a_item) == -1;
            })
        );
        return arr;
    },
    list_page_filter(list, page, page_size = 10) {
        var arr = [...list];
        arr = arr.slice((page - 1) * page_size, page * page_size);
        return arr;
    },
    arr_a_filter_b(a, b = []) {
        return a.filter((a_item) => {
            return b.indexOf(a_item) == -1;
        });
    },
    str_filter(val, _config, recursion) {
        _config = {
            s: 6,
            e: 4,
            ..._config,
        };
        if (!val) {
            return val;
        }
        return (
            val.substring(0, _config.s) +
            "..." +
            val.substring(val.length - _config.e)
        );
    },
    /*testFun(){
        console.log("testFun",this)
    }*/
    createDownLoad(content, fileName) {
        const link = document.createElement("a");
        link.href = content;
        const d = new Date();
        link.download =
            fileName ||
            d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        link.target = "_blank";
        const ev = document.createEvent("MouseEvents");
        ev.initMouseEvent(
            "click",
            true,
            false,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
        );
        link.dispatchEvent(ev);
    },
};
// String.prototype._ajax = obj
// String.prototype._testFun = obj.testFun

export default obj;